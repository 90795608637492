import { useState, useEffect } from 'react';
import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Form,
  Space,
  Table,
  Typography,
  Row,
  Col,
  Divider,
  Card,
  FormInstance,
  Tooltip,
  Switch
} from 'antd';
import { useAppSelector } from '../../store';
import {
  selectProducts,
  selectUnitOptions
} from '../../store/slices/product.slices';
import { selectPrestations } from '../../store/slices/prestation.slices';
import { selectTimeSlots } from '../../store/slices/time_slot.slices';
import ProductCell from '../ProductCell';
import { Product, OrderProduct, OrderLineType } from '../../models';
import { frenchCurrencyFormat } from '../../utils';
import { selectProductTypes } from '../../store/slices/product_type.slices';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import ProductRow from 'components/ProductRow';

const { Text } = Typography;

type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export type ColumnOptions = {
  record: OrderProduct;
  editable?: boolean;
  dataIndex?: keyof OrderProduct;
  title?: any;
  options?: {
    products?: Product[];
  };
};

let itemOrder = 0;
const OrderProducts = ({
  disabled,
  form,
  items,
  calItemPrice,
  taxPercentage
}: {
  disabled?: boolean;
  form: FormInstance;
  items: OrderProduct[];
  calItemPrice: Function;
  taxPercentage: number;
}) => {
  const [dataSource, setDataSource] = useState<OrderProduct[]>(items);
  const products = useAppSelector(selectProducts);
  const unitOptions = useAppSelector(selectUnitOptions);
  const prestations = useAppSelector(selectPrestations);
  const timeSlots = useAppSelector(selectTimeSlots);
  const productTypes = useAppSelector(selectProductTypes);

  useEffect(() => {
    itemOrder = dataSource?.length;
  }, []);
  const updateDataSource = (data: any, uuid: any) => {
    const newDataSource = [...dataSource];
    const index = newDataSource.findIndex((i) => i.uuid === uuid);
    const updatedProduct = { ...newDataSource[index], ...data };
    const { subTotal, total } = calItemPrice(updatedProduct);
    updatedProduct.total = total;
    updatedProduct.subTotal = subTotal;
    newDataSource[index] = updatedProduct;
    setDataSource(newDataSource);
  };

  const handleDelete = (uuid: any) => {
    form.setFieldsValue({ [`line_items.${uuid}`]: undefined });
    const newData = dataSource.filter((i) => i.uuid !== uuid);
    setDataSource(newData);
  };

  const handleAddNewRow = (creationType: OrderLineType) => {
    if(dataSource.length > 0){
      itemOrder = dataSource[dataSource.length - 1]?.item_order + 1;
    }else{
      itemOrder = 1
    }
    let newRow: OrderProduct = {
      uuid: uuidv4(),
      item_order: itemOrder,
      creationType,
      name: ''
    };
    if (creationType !== 'header') {
      newRow = {
        ...newRow,
        total: 0,
        creationType,
        quantity: 1,
        discount: 0,
        discount_unit: '%',
        description: '',
        item_custom_fields: []
      };
    }
    setDataSource([...dataSource, newRow]);
  };
  const handleSwitchValue = (toggled: boolean, uuid: any) => {
    updateDataSource({product0: toggled}, uuid);
    form.setFieldsValue({
      line_items: {
        [`${uuid}`]: {
          product0: toggled,
        }
      }
    });
  }
  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      key: 'sort',
      width: 75,
      dataIndex: 'item_id',
    },
    {
      width: 575,
      title: 'Produit/ Description',
      dataIndex: 'name',
      editable: true,
      render: (_, record: any) => {
        if (record.creationType === 'header') {
          return {
            props: {
              colSpan: 7
            }
          };
        }
      }
    },
    {
      width: 200,
      title: 'Prestation',
      dataIndex: 'item_custom_fields',
      editable: true,
      render: (_, record: any) => {
        if (record.creationType === 'header') {
          return {
            props: { colSpan: 0 }
          };
        }
      }
    },
    {
      width: 160,
      title: 'Prix unitaire',
      dataIndex: 'rate',
      editable: true,
      render: (_, record: any) => {
        if (record.creationType === 'header') {
          return {
            props: { colSpan: 0 }
          };
        }
      }
    },
    {
      width: 110,
      title: 'Quantité',
      dataIndex: 'quantity',
      editable: true,
      render: (_, record: any) => {
        if (record.creationType === 'header') {
          return {
            props: { colSpan: 0 }
          };
        }
      }
    },
    {
      width: 140,
      title: 'Sous-total',
      dataIndex: 'total',
      render: (_, record: any, index: number) => {
        if (record.creationType === 'header') {
          return {
            props: { colSpan: 0 }
          };
        }
        return (
          <div style={{ minWidth: '100px' }} className='text-right'>
            <Form.Item name={['line_items', `${record.uuid}`, 'total']}>
              <Space direction='horizontal' align='baseline'>
                <Text style={{ lineHeight: '32px' }}>{`${frenchCurrencyFormat(
                  (record.subTotal ?? 0).toFixed(2)
                )} €`}</Text>
              </Space>
            </Form.Item>
          </div>
        );
      }
    },
    {
      width: 150,
      title: 'Remise',
      dataIndex: 'discount',
      editable: true,
      render: (_, record: any) => {
        if (record.creationType === 'header') {
          return {
            props: { colSpan: 0 }
          };
        }
      }
    },
    {
      width: 120,
      title: 'Total',
      dataIndex: 'total',
      fixed: 'right',
      render: (_, record: any, index: number) => {
        if (record.creationType === 'header') {
          return {
            props: { colSpan: 0 }
          };
        }
        return (
          <div style={{ minWidth: '50px' }} className='text-center'>
            <Space direction='vertical' align='baseline'>
              <Text style={{ lineHeight: '32px' }} strong>
                {`${frenchCurrencyFormat((record.total ?? 0).toFixed(2))} €`}
              </Text>
              <Form.Item
                name={['line_items', `${record.uuid}`, 'product0']}
                initialValue={record.product0}>
                <Tooltip placement="bottom" title="Mis à 0" >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={record.product0}
                    defaultChecked={record.product0}
                    onChange={(e)=>{
                      handleSwitchValue(e.valueOf(), record.uuid)
                    }}
                  />
                </Tooltip>
              </Form.Item>
            </Space>
          </div>
        );
      }
    },
    {
      width: 75,
      title: 'Action',
      dataIndex: 'item_id',
      fixed: 'right',
      render: (_, record: any) => {
        return (
          <div>
            <Button
              type='link'
              onClick={() => handleDelete(record.uuid)}
              icon={
                <DeleteOutlined
                  style={{ fontSize: '16px', color: '#FF4D4F' }}
                />
              }
              className='datatable__action-destroy-button'
            />
          </div>
        );
      }
    }
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: OrderProduct) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        form,
        title: col.title,
        calItemPrice,
        updateDataSource,
        setDataSource,
        dataSource,
        options: {
          products,
          prestations,
          timeSlots,
          unitOptions,
          productTypes
        },
        formType: 'order'
      })
    };
  });

  const subTotal = dataSource.reduce((total, secondItem) => {
    return total + (secondItem?.total || 0);
  }, 0);

  const handleChangeDataSwap = (index : any, changeIndex : any) => {
    form.setFieldsValue({
      line_items: {
        [`${index.id}`]: {
          item_order: changeIndex,
        }
      }
    })
  }

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((item) => item.uuid === active.id);
        const overIndex = prev.findIndex((item) => item.uuid === over?.id);
        handleChangeDataSwap(active, overIndex);
        handleChangeDataSwap(over, activeIndex);
        return arrayMove(prev, activeIndex, overIndex);
      })
    }
  }

  const handleRedirectPrixResearch = () => {
    const qboUrl = process.env.REACT_APP_QBO_APP_URL;
    const fullPath = `${qboUrl}/search/commercial`;
    window.open(fullPath, '_blank');
  };

  return (
    <>
      <section className='mt-2 section quotation'>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
          <SortableContext items={dataSource.map((item) => item.uuid)} strategy={verticalListSortingStrategy}>
            <Table
              scroll={{ x: 1300 }}
              bordered
              rowKey={'uuid'}
              components={{
                body: {
                  row: ProductRow,
                    cell: ProductCell
                  }
              }}
              rowClassName={(record) =>
                record.creationType === 'header' ? 'quotation-title-line' : ''
              }
              dataSource={dataSource}
              columns={columns as ColumnTypes}
              pagination={false}
            />
          </SortableContext>
        </DndContext>
        <Row justify='space-between' className='mt-8'>
          {!disabled && (
            <Space
              direction='horizontal'
              align='start'
              className='quotation__button-block'>
              <div className='btn-add__creation'>
                <Button
                  size='large'
                  type='primary'
                  className={`btn-add__creation-button`}
                  onClick={() => handleAddNewRow('product')}
                  style={{
                    width: 'auto',
                    height: '32px',
                    borderRadius: '6px'
                  }}>
                  <PlusCircleOutlined className='btn-add__creation-icon' />
                  <span className='btn-add__creation-text'>
                    Ajouter un article du catalogue
                  </span>
                </Button>
              </div>
              {/* <div className='btn-add__creation'>
                <Button
                  size='large'
                  type='primary'
                  className={`btn-add__creation-button`}
                  onClick={() => handleAddNewRow('custom')}
                  style={{
                    width: 'auto',
                    height: '32px',
                    borderRadius: '6px'
                  }}>
                  <PlusCircleOutlined className='btn-add__creation-icon' />
                  <span className='btn-add__creation-text'>
                    Ajouter un article hors catalogue
                  </span>
                </Button>
              </div> */}
              <div className='btn-add__creation'>
                <Button
                  size='large'
                  type='primary'
                  className={`btn-add__creation-button`}
                  onClick={() => handleAddNewRow('header')}
                  style={{
                    width: 'auto',
                    height: '32px',
                    borderRadius: '6px'
                  }}>
                  <PlusCircleOutlined className='btn-add__creation-icon' />
                  <span className='btn-add__creation-text'>
                    Ajouter une en-tête
                  </span>
                </Button>
              </div>
              <div className='btn-add__creation'>
                <Button
                  size='large'
                  type='primary'
                  className='btn-add__filled-creation-button'
                  onClick={() => handleRedirectPrixResearch()}
                  style={{
                    height: '32px',
                    borderRadius: '6px',
                    background: '#A6C84D',
                  }}>
                  <PlusCircleOutlined
                    className='btn-add__creation-icon'
                    style={{
                      color: '#FFF',
                    }}
                  />
                  <span
                    className='btn-add__creation-text'
                    style={{
                      color: '#FFF',
                    }}>
                    Recherche de prix
                  </span>
                </Button>
              </div>
            </Space>
          )}
          <Space 
            direction='horizontal'
            align='start'
            className='quotation__subtotal-block'>
            <Card
              bordered={false}
              className='quotation__subtotal-card'
              style={{ width: '300px' }}>
              <Row>
                <Col
                  className='quotation__subtotal-left-col'
                  style={{ borderTopLeftRadius: '8px' }}>
                  Sous-total
                </Col>
                <Col
                  className='quotation__subtotal-right-col'
                  style={{ borderTopRightRadius: '8px' }}>
                  {`${frenchCurrencyFormat(subTotal.toFixed(2))}`} €
                </Col>
              </Row>
              <Row>
                <Col className='quotation__subtotal-left-col'>
                  TVA ({taxPercentage}%)
                </Col>
                <Col className='quotation__subtotal-right-col'>
                  {`${frenchCurrencyFormat(
                    (subTotal * (taxPercentage / 100)).toFixed(2)
                  )}`}{' '}
                  €
                </Col>
              </Row>
              <Divider
                style={{
                  borderBlockStart: '1px solid rgba(0, 0, 0, 0.25)',
                  margin: 0
                }}
              />
              <Row>
                <Col
                  className='quotation__subtotal-left-col'
                  style={{ borderBottomLeftRadius: '8px' }}>
                  <span className='quotation__total-text'>Total</span>
                </Col>
                <Col
                  className='quotation__subtotal-right-col'
                  style={{ borderBottomRightRadius: '8px' }}>
                  <span className='quotation__total-text'>
                    {`${frenchCurrencyFormat(
                      (subTotal + subTotal * (taxPercentage / 100)).toFixed(2)
                    )}`}{' '}
                    €
                  </span>
                </Col>
              </Row>
            </Card>
          </Space>
        </Row>
      </section>
    </>
  );
};
export default OrderProducts;
